body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: x-small;
}

.site-container {
  min-height: 100vh;
}

/* header {
  background-color: #404040;
  padding: 1rem;
}

header a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
} */

main {
  /* //margin: 1rem; */
  flex: 1;
}
.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: black;
}
.customers {
  /* display: flex; */
  justify-content: center;
}

.products {
  /* display: flex; */
  justify-content: center;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}

.produt-info {
  padding: 1rem;
}

.small-container {
  max-width: 600px;
}

.e-input {
  width: auto;
}

/* .input-group .quantity-field {
  position: relative;
  height: 38px;
  left: -6px;
  text-align: center;
  width: 62px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
} */
.qty-btn {
  /* margin: 0;
  padding-bottom: 10px;
  height: 10px;
  width: 2px; */
  /* margin: 0px;
  padding-top: 0px;
  display: inline-flex;
  align-items: flex-start; */
}

.cart-btn {
  margin: 0;
  padding: 0;
  height: 1px;
}
.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}
/*side bar*/
.navbar-brand {
  font-weight: bold;
}

.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5s;
}
.side-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}

.active-nav {
  left: 0;
}

.text-bold {
  font-weight: bold;
}

.prod-col {
  padding: 3px;
  border: solid 1px #eeeeee;
  margin-top: 0;
  margin-bottom: 0;
  /* border-right-color: white; */
}
.selected-row {
  background-color: #f0c040;
  font-weight: bold;
  color: white;
  transition: 0.5s;
}
.row-bonus {
  background-color: rgb(215, 237, 248);
}

.row-lock {
  background-color: rgb(252, 197, 175);
  /* color: white; */
}
.selected-row-lock {
  background-color: #f08640;
  font-weight: bold;
  color: darkred;
  transition: 0.5s;
}
.prod-price-col {
  text-align: right;
  padding-right: 10px !important;
}

.cart-span > i {
  /* color: white; */
}
.cart-span > input {
  background: none;
  left: 50px;
  color: white;
  padding: 0;
  border: 0;
}

.card-prod-body {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  /* border: green 5px solid; */
}

.card-prod {
  margin: 2px;
  border: none;
  /* border: red 5px solid; */
}

.row-prod {
  /* border: #100901 5px solid; */
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 0;
}

/* { new css} */

.button-cart {
  /* width: 0px !important;
  padding: 0 !important;
  margin: 0% !important; */
  margin-left: -20px;
  padding-top: 0;
}

input,
textarea {
  border: 1px solid #eeeeee;
  /* box-sizing: border-box; */
  outline: none;
  /* height: 10px; */
  width: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 0px;
  padding: 0px;
  position: relative;
  /* border-style: dashed; */
  /* width: 80px; */
}

.input-group input[type='button'] {
  /* background-color: #eeeeee; */
  min-width: 15px;
  width: auto;
  margin: 0;
  padding: 0;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus,
.input-group .button-cart {
  font-weight: bold;
  height: 20px;
  padding: 0;
  margin: 0;
  width: 20px;
  position: relative;
}

.input-group .quantity-field {
  position: relative;
  height: 20px;
  /* left: -6px; */
  text-align: center;
  width: 40px;
  display: inline-block;
  font-size: xx-small;
  margin: 0 0 0px 0;
  padding: 0;
  resize: vertical;
}

.button-plus {
  /* left: -13px; */
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.products-heading {
  display: flex;
  justify-content: space-between;
}

.mainbtn {
  display: block;
  margin: 20px auto;
  width: 75%;
  height: 100px;
}

.maindiv {
  margin-top: 0px;
  width: 100%;
}

.btn-cart-clear {
  background-color: black;
  height: 40px;
  color: white;
  font-weight: bold;
}
